import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import ScrollToTopComponent from '@mtcn/scroll-to-top';
import TopImageBanner from '@mtcn/top-image-banner';
import {PageTitle} from '@mtcn/ui-components';
import {useContentProvider} from '@mtcn/content-provider';

const Container = styled.div``;

const WorshipInstructionsContainer = styled.div`
    text-align: left;
    width: 92%;
    margin: 20px auto;
    padding-top: 10px
    -webkit-box-shadow: 0px 0px 21px -2px rgb(0 0 0 / 37%);
    -moz-box-shadow: 0px 0px 21px -2px rgba(0,0,0,0.37);
    box-shadow: 0px 0px 21px -2px rgb(0 0 0 / 37%);
`;

const InstructionsItem = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 30px;
    padding: 20px;
    h3 {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    ul {
      margin-top: 0px;
      margin-bottom: 0px;
    }
`;

const GoogleCalendarComponent = styled.iframe`
    height: 500px;
    border: solid 1px #777;
    width: 92%;
    margin: 10px auto 0px auto;
    -webkit-box-shadow: 0px 0px 21px -2px rgb(0 0 0 / 37%);
    -moz-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 0px 21px -2px rgb(0 0 0 / 37%);
`;

const WorshipPage = () => {
    const {pageBanner, pageTitle, instructions} = useContentProvider().worshipPage.fields;
    const bannerAlt = pageBanner.fields.title;
    const bannerSrc = pageBanner.fields.file.url;

    return (
        <Container>
            <ScrollToTopComponent />
            <TopImageBanner imgAlt={bannerAlt} imgSrc={bannerSrc} />
            <PageTitle title={pageTitle} />
            <WorshipInstructionsContainer>
                {instructions && <InstructionsItem><ReactMarkdown source={instructions} /> </InstructionsItem>}
            </WorshipInstructionsContainer>
            <GoogleCalendarComponent
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23c52e27&ctz=America%2FChicago&mode=AGENDA&showNav=1&showTitle=0&showDate=1&showPrint=0&showTabs=0&showCalendars=0&src=bXRjbmFzaHZpbGxlQGdtYWlsLmNvbQ&color=%23039BE5"
                frameBorder="0"
                scrolling="yes"
            />
        </Container>
    );
};

export default WorshipPage;
