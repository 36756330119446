import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import ScrollToTopComponent from '@mtcn/scroll-to-top';
import TopImageBanner from '@mtcn/top-image-banner';
import {FromVicarMessage} from '@mtcn/ui-components';
import MapComponent from '@mtcn/map-component';
import {BreakPoints} from '@mtcn/media-query';
import {useContentProvider} from '@mtcn/content-provider';

const Container = styled.div``;

const AlertMessageContainer = styled.div`
    margin: auto;
    padding: 6px 0px;
    background: #9e2019;
    background: -webkit-linear-gradient(top, #9e2019, #ffffff);
    background: -moz-linear-gradient(top, #9e2019, #ffffff);
    background: linear-gradient(to bottom, #9e2019, #ffffff);
`;

const IntroContainer = styled.div`
    width: 96%;
    margin: auto;
    padding-bottom: 40px;
    color: #2f2e2e;
    @media ${BreakPoints.mobileBig} {
        width: 80%;
    }
    p {
        line-height: 30px;
        margin-top: 6px;
        margin-bottom: 6px;
    }
`;

const IntroTitle = styled.div`
    font-size: 24px;
    letter-spacing: 1px;
    color: #012646;
    font-weight: 600;
    padding: 40px 0px 20px 0px;
`;

const IntroDescriptionContainerr = styled.div``;

const MapContainer = styled.div`
    padding: 40px 0px 50px 0px;
`;

const HomePage = () => {
    const providerData = useContentProvider();
    const headerData = providerData.header.fields;
    const {churchLongName} = headerData;

    const homePageData = providerData.homePage.fields;
    const {aboutChurch, vicarLogo, vicarMessage, homeBannerImages, alertMessage} = homePageData;

    const {vicarName} = providerData.contactsPage.fields;

    return (
        <Container>
            <ScrollToTopComponent />
            <Carousel
                showArrows={true}
                showThumbs={false}
                infiniteLoop={true}
                useKeyboardArrows={true}
                autoPlay={true}
                showStatus={false}
            >
                {homeBannerImages &&
                    homeBannerImages.length > 0 &&
                    homeBannerImages.map((homeBannerImage: any, index: number) => (
                        <TopImageBanner
                            imgAlt={homeBannerImage.fields.title}
                            imgSrc={homeBannerImage.fields.file.url}
                            key={index}
                        />
                    ))}
            </Carousel>
            {alertMessage && (
                <AlertMessageContainer>
                    <ReactMarkdown source={alertMessage} />
                </AlertMessageContainer>
            )}
            <IntroContainer>
                <IntroTitle>{`Welcome to ${churchLongName}`}</IntroTitle>
                <IntroDescriptionContainerr>
                    {aboutChurch && <ReactMarkdown source={aboutChurch} />}
                </IntroDescriptionContainerr>
            </IntroContainer>
            <FromVicarMessage
                iconSrc={vicarLogo.fields.file.url}
                iconAlt={vicarLogo.fields.title}
                width={'80%'}
                title={`From our vicar - ${vicarName}`}
                msg={vicarMessage}
            />
            <MapContainer>
                <MapComponent />
            </MapContainer>
        </Container>
    );
};

export default HomePage;
