import React from 'react';
import styled from '@emotion/styled';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Header} from '@mtcn/header';
import {Footer} from '@mtcn/footer';
import ScrollToTopComponent from '@mtcn/scroll-to-top';
import {ContentProvider} from '@mtcn/content-provider';
import HomePage from './pages/Home';
import WorshipPage from './pages/Worship';
import CommitteePage from './pages/Committee';
import GalleryPage from './pages/Gallery';
import ContactPage from './pages/Contact';
import './App.css';

const BodyContainer = styled.main`
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

function App() {

  return (
        <ContentProvider>
            <Router>
                <ScrollToTopComponent />
                <div className="App">
                    <Header />
                    <BodyContainer>
                        <Switch>
                            <Route path="/worship" component={WorshipPage} />
                            <Route path="/gallery" component={GalleryPage} />
                            <Route path="/committee" component={CommitteePage} />
                            <Route path="/contact" component={ContactPage} />
                            <Route default component={HomePage} />
                        </Switch>
                    </BodyContainer>
                    <Footer />
                </div>
            </Router>
        </ContentProvider>
    );
}

export default App;
