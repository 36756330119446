import React, {useState} from 'react';
import styled from '@emotion/styled';
import Carousel, {Modal, ModalGateway} from 'react-images';
import ScrollToTopComponent from '@mtcn/scroll-to-top';
import TopImageBanner from '@mtcn/top-image-banner';
import {PageTitle} from '@mtcn/ui-components';
import {BreakPoints} from '@mtcn/media-query';
import {useContentProvider} from '@mtcn/content-provider';

const PageContainer = styled.div``;

const FollowUsContainer = styled.div`
    margin: auto 40px;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;

`;

const FollowUsItem = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const FollowUsItemTitle = styled.div`
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
`;
const FollowUsItemIcon = styled.img`
    height: 50px;
`;

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    padding: 40px;
    @media ${BreakPoints.mobileBig} {
        grid-template-columns: auto auto;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding: 40px;
    }
    @media ${BreakPoints.tablet} {
        grid-template-columns: auto auto auto;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding: 40px;
    }
`;

const ImageContainer = styled.div`
    -webkit-box-shadow: 0px 0px 58px 15px rgba(0,0,0,0.27);
    -moz-box-shadow: 0px 0px 58px 15px rgba(0,0,0,0.27);
    box-shadow: 0px 0px 58px 15px rgba(0,0,0,0.27);
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer
}
`;

const ImageItem = styled.img`
    width: 100%;
`;

const ViewMore = styled.div`
    letter-spacing: 0.3px;
    font-size: 16px;
    color: #c52f27;
    font-weight: 600;
`;

const ViewMoreFollowUs = styled(FollowUsContainer)`
    justify-content: center;
`;

const GalleryPage = () => {
    const providerData = useContentProvider();
    const {pageTitle, pageBanner, images, followUsOnSocialmedia} = providerData.galleryPage.fields;
    const bannerAlt = pageBanner.fields.title;
    const bannerSrc = pageBanner.fields.file.url;

    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState<boolean>(false);

    const imageArray: {src: string}[] = images.map((imageItem: any) => ({src: imageItem.fields.file.url}));

    const toggleLightbox = (selectedIndex: number) => {
        setLightboxIsOpen(!lightboxIsOpen);
        setSelectedIndex(selectedIndex);
    };
    
    const renderFollowUsOnSocialMedia = () =>
        followUsOnSocialmedia &&
        followUsOnSocialmedia.length !== 0 &&
        followUsOnSocialmedia.map((item: any, index: number) =>
            item.fields.description ?
                (<FollowUsItem
                    key={`followus-item-${index}`}>
                    <FollowUsItemTitle
                        key={`followus-item-title-${index}`}> 
                        {item.fields.title}
                    </FollowUsItemTitle>
                    <FollowUsItemIcon
                        onClick={() => window.open(item.fields.description)}
                        key={`followus-item-icon-${index}`}
                        alt={'caption'}
                        src={item.fields.file.url}
                        style={{ }}
                    />
                </FollowUsItem>) :
                (<React.Fragment 
                    key={`followus-item-fragment-${index}`}/>),
        );

    renderFollowUsOnSocialMedia();
    return (
        <PageContainer>
            <ScrollToTopComponent />
            <TopImageBanner imgAlt={bannerAlt} imgSrc={bannerSrc} />
            <PageTitle title={pageTitle} />
            <FollowUsContainer>
                {
                    renderFollowUsOnSocialMedia()
                }
            </FollowUsContainer>
            <ContentContainer>
                {imageArray.map(({src}, j) => (
                    <ImageContainer key={`gallery-img-container-${j}`}>
                        <ImageItem
                            onClick={() => toggleLightbox(j)}
                            key={`gallery-img-${j}`}
                            alt={'caption'}
                            src={src}
                            style={{
                                width: '100%',
                            }}
                        />
                    </ImageContainer>
                ))}
            </ContentContainer>
            <ViewMore>
              view more @
            </ViewMore>
            <ViewMoreFollowUs>
                  {
                      renderFollowUsOnSocialMedia()
                  }
              </ViewMoreFollowUs>
            <ModalGateway>
                {lightboxIsOpen ? (
                    <Modal onClose={toggleLightbox}>
                        <Carousel currentIndex={selectedIndex} frameProps={{autoSize: 'height'}} views={imageArray} />
                    </Modal>
                ) : null}
            </ModalGateway>
        </PageContainer>
    );
};

export default GalleryPage;
