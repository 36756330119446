import React from 'react';
import styled from '@emotion/styled';
import ScrollToTopComponent from '@mtcn/scroll-to-top';
import TopImageBanner from '@mtcn/top-image-banner';
import {PageTitle, ItemTitle} from '@mtcn/ui-components';
import {BreakPoints} from '@mtcn/media-query';
import {useContentProvider} from '@mtcn/content-provider';

const PageContainer = styled.div``;

const CommitteeContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    padding: 40px;
    max-width: 1044px;
    margin: auto;
    @media ${BreakPoints.mobileBig} {
        grid-template-columns: auto auto;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding: 40px;
    }
    @media ${BreakPoints.tablet} {
        grid-template-columns: auto auto auto;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        padding: 40px;
    }
`;

const CommitteeMemberItemContainer = styled.div`
    padding: 20px;
    border: 1px solid lightgray;
    -webkit-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    cursor: pointer;
    &:nth-of-type(2n + 1) {
        background: #e2eefa;
    }
    &:hover {
        -webkit-box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
        -moz-box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
        box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
    }
    @media ${BreakPoints.tablet} {
        height: 160px;
    }
`;

const MemberInfo = styled.p`
    font-size: 18px;
    letter-spacing: 1px;
    text-align: left;
`;

const CommitteePage = () => {
    const providerData = useContentProvider();
    const {pageTitle, pageBanner, committeeMembers} = providerData.commiteePage.fields;
    const bannerAlt = pageBanner.fields.title;
    const bannerSrc = pageBanner.fields.file.url;

    return (
        <PageContainer>
            <ScrollToTopComponent />
            <TopImageBanner imgAlt={bannerAlt} imgSrc={bannerSrc} />
            <PageTitle title={pageTitle} />
            <CommitteeContainer>
                {committeeMembers.map((committeeMember: {name: string; title: string}, index: number) => (
                    <CommitteeMemberItemContainer key={`committeeContainerItem${index}`}>
                        <ItemTitle key={`memberTitle${index}`} title={committeeMember.title} textAlign={'left'} />
                        <MemberInfo key={`memberInfo${index}`}>{committeeMember.name}</MemberInfo>
                    </CommitteeMemberItemContainer>
                ))}
            </CommitteeContainer>
        </PageContainer>
    );
};

export default CommitteePage;
