import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import ScrollToTopComponent from '@mtcn/scroll-to-top';
import TopImageBanner from '@mtcn/top-image-banner';
import AddressInfoComponent from '@mtcn/address-info-component';
import {PageTitle} from '@mtcn/ui-components';
import MapComponent from '@mtcn/map-component';
import {BreakPoints} from '@mtcn/media-query';
import {useContentProvider} from '@mtcn/content-provider';

const Container = styled.div``;

const ContactContainer = styled.div`
    padding: 40px 0px;
    width: 96%;
    margin: auto;
    @media ${BreakPoints.mobileBig} {
        width: 80%;
    }
`;

const AddressInfoComponentWrapper = styled(AddressInfoComponent)`
    -webkit-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    padding: 20px;
    &:hover {
        -webkit-box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
        -moz-box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
        box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
    }
    @media ${BreakPoints.mobileBig} {
        padding: 50px;
    }
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 6px;
    margin: 20px auto;
    -webkit-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.37);
    &:hover {
        -webkit-box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
        -moz-box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
        box-shadow: 0px 0px 61px 2px rgba(0, 0, 0, 0.83);
    }
    @media ${BreakPoints.mobileBig} {
        padding: 50px;
    }
`;

const MapContainer = styled.div`
    padding: 40px 0px 50px 0px;
`;

function ContactPage() {
    const {pageTitle, pageBanner, instructions} = useContentProvider().contactsPage.fields;

    const bannerAlt = pageBanner.fields.title;
    const bannerSrc = pageBanner.fields.file.url;

    return (
        <Container>
            <ScrollToTopComponent />
            <TopImageBanner imgAlt={bannerAlt} imgSrc={bannerSrc} />
            <PageTitle title={pageTitle} />
            <ContactContainer>
                <AddressInfoComponentWrapper />
                <MessageContainer>{instructions && <ReactMarkdown source={instructions} />}</MessageContainer>
                <MapContainer>
                    <MapComponent />
                </MapContainer>
            </ContactContainer>
        </Container>
    );
}

export default ContactPage;
